import Api from '@/axios/api'
import genInventoryProductUrl from '@/components/myInventory/utils/genInventoryProductUrl'

export default {
  async getSourceItems ({ commit, rootState }) {
    commit('setSourceLoading', true)

    const { userIsLoggedIn } = rootState.headerAuth ?? { userIsLoggedIn: false }

    const promises = [
      Api.get('/shared/collections/themes')
        .then(({ data }) => data)
        .catch(() => [])
    ]

    if (userIsLoggedIn) {
      promises.push(Api.get('/customer/wishlists').then(({ data }) => data).catch(() => []))
      promises.push(Api.get('/customer/purchased-inventory/all').then(({ data }) => data).catch(() => []))
    } else {
      promises.push(Promise.resolve([]))
      promises.push(Promise.resolve([]))
    }

    Promise.all(promises)
      .then((response) => {
        const [collectionsData, favoritesData, inventoryData] = response

        commit('setSourceCollections', collectionsData)
        commit('setSourceFavorites', favoritesData)
        commit('setSourceInventory', inventoryData)
      })
      .finally(() => (commit('setSourceLoading', false)))
  },
  async getCampaignNames ({ commit, rootState }) {
    const { userIsLoggedIn } = rootState.headerAuth ?? { userIsLoggedIn: false }

    if (!userIsLoggedIn) { return Promise.resolve([]) }

    Api.get('/campaigns/names')
      .then(({ data }) => (commit('setAlreadyUsedNames', data)))
      .catch(() => (commit('setAlreadyUsedNames', [])))
  },
  async getUserLogos ({ commit, rootState }) {
    const { userIsLoggedIn } = rootState.headerAuth ?? { userIsLoggedIn: false }

    if (!userIsLoggedIn) { return Promise.resolve([]) }

    Api.get('/customer/logos')
      .then(({ data }) => (commit('setUserLogos', data)))
      .catch(() => (commit('setUserLogos', [])))
  },
  async loadCampaignData ({ commit, dispatch }, id) {
    commit('setSourceLoading', true)

    return Api.get(`campaigns/${id}`)
      .then(({ data }) => {
        const greetingMessage = data.greeting_message ?? ''
        const greetingMessageChunks = greetingMessage.split('\n\n')

        let title = ''
        let content = ''
        let footer = ''

        if (greetingMessageChunks.length > 2) {
          title = greetingMessageChunks.shift()
          footer = greetingMessageChunks.pop()
          content = greetingMessageChunks.join('\n\n')
        } else {
          content = greetingMessage
        }

        const campaignProducts = data.products?.map((product) => ({
          id: product.id,
          sourceId: product.source_id,
          isInventory: product.source_type === 'inventory',
          isCollection: product.source_type === 'collection',
          image: product.image,
          name: product.name,
          price: product.price,
          url: product.source_type === 'inventory'
            ? genInventoryProductUrl(product.product_url)
            : product.product_url,
        })) ?? []
        
        dispatch('getSourceItems')
        commit('setCampaignName', data.name)
        commit('setGreetingMessageTitle', title || null)
        commit('setGreetingMessageContent', content || null)
        commit('setGreetingMessageFooter', footer || null)
        commit('setGreetingMessageLogo', data.logo_url)
        commit('setDisplayName', data.gift_giver_name)
        commit('addNewSelectedProducts', campaignProducts)
        commit('setEmailSubjectLine', data.email_subject_line)
        commit('setExpirationDays', data.expire_after || 90)
        commit('setVideoGreeting', data.video_greeting)
        commit('setAllowExchange', data.enable_upgrade)
        commit('setDisableThankYouNotes', data.skip_thankyou_message)
        commit('setDisableReminderEmails', data.disable_recipient_reminders)
        commit('setAddSuccessButton', !!(data.free_form_button || data.free_form_url))
        commit('setSuccessButtonURL', data.free_form_url)
        commit('setSuccessButtonLabel', data.free_form_button)
      })
      .catch(() => {
        // TODO handle errors
      })
  }
}
