<template>
  <div class="create-campaign-source-favorites">
    <h1>Select eGiftable options from your favorites</h1>

    <cg-loader v-if="loading" />

    <div v-else class="create-campaign__products-wrapper">
      <div class="create-campaign-source-favorites__products-actions">
        <div class="create-campaign__products-actions">
          <cg-switch v-model="hideNonEGiftableItems">
            <div class="d-flex flex-row flex-nowrap g10">
              Hide non-eGiftable items
              <common-tooltip :activator-size="20" activator-color="#222325" :max-width="310">
                Non eGiftable items cannot be sent as an eGift, therefore cannot be part of campaigns
              </common-tooltip>
            </div>
          </cg-switch>

          <cg-button
            :disabled="!selectedProductsIds.length"
            @click="goToNextPage"
          >
            Add selected

            <template #append-icon>
              <div class="create-campaign__button-badge">
                {{ selectedProductsIds.length }}
              </div>
            </template>
          </cg-button>
        </div>

        <v-chip-group
          :column="!$vuetify.breakpoint.xsOnly"
          :show-arrows="false"
        >
          <the-my-favorites-list-item
            v-for="availableItem in availableLists"
            :key="availableItem.id"
            :isSelected="availableItem.id === selectedListId"
            :listData="availableItem"
            tabindex="0"
            @click.native="selectedListId = availableItem.id"
            @keypress.native="selectedListId = availableItem.id"
          />
        </v-chip-group>

        <cg-switch v-model="hideNonEGiftableItems">
          <div class="d-flex flex-row flex-nowrap g10">
            Hide non-eGiftable items
            <common-tooltip :activator-size="20" activator-color="#222325" :max-width="310">
              Non eGiftable items cannot be sent as an eGift, therefore cannot be part of campaigns
            </common-tooltip>
          </div>
        </cg-switch>

        <div class="create-campaign__products-buttons-group create-campaign-source-favorites__products-filter">
          <span
            role="button"
            :tabindex="disableSelectAll ? '-1' : '0'"
            @click="addFilteredProducts"
            @keyup.enter.space="addFilteredProducts"
            :aria-disabled="disableSelectAll ? 'true' : 'false'"
          >
            Select all
          </span>
          <span
            role="button"
            :tabindex="disableClearAll ? '-1' : '0'"
            @click="removeFilteredProducts"
            @keyup.enter.space="removeFilteredProducts"
            :aria-disabled="disableClearAll ? 'true' : 'false'"
          >
            Clear all
          </span>
        </div>
      </div>

      <div class="create-campaign__products-grid">
        <product-card
          v-for="product in filteredProducts"
          :key="product.product_id"
          :id="product.product_id"
          :is-selected="selectedProductsIds.includes(product.product_id)"
          :image="product.product.image"
          :name="product.product.name"
          :price="product.product.price"
          :url="product.link"
          :disabled="productIsNotEGiftable(product)"
          @addProduct="onAddProduct"
          @removeProduct="onRemoveProduct"
        />
      </div>
    </div>

    <div v-if="!loading" class="create-campaign__sticky-button">
      <cg-button
        :disabled="!selectedProductsIds.length"
        block
        @click="goToNextPage"
      >
        Add selected

        <template #append-icon>
          <div class="create-campaign__button-badge">
            {{ selectedProductsIds.length }}
          </div>
        </template>
      </cg-button>
    </div>
  </div>
</template>

<script>
import Api from '@/axios/api'
import { createNamespacedHelpers } from 'vuex'
import maxGiftsGuard from '@/components/createCampaign/mixins/maxGiftsGuard'

import { CgLoader, CgButton, CgSwitch } from '@corporategift/design-system'
import TheMyFavoritesListItem from '@/components/myFavorites/TheMyFavoritesListItem.vue'
import ProductCard from '@/components/createCampaign/ProductCard.vue'

const { mapState, mapMutations } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignSourceFavorites',
  mixins: [maxGiftsGuard],
  components: {
    CgSwitch,
    CgButton,
    CgLoader,
    ProductCard,
    TheMyFavoritesListItem,
  },
  data: () => ({
    loading: false,
    abortController: null,
    selectedListId: null,
    hideNonEGiftableItems: false,
    availableProducts: [],
    selectedProductsIds: [],
  }),
  computed: {
    ...mapState(['source']),
    availableLists () {
      return this.source.favorites ?? []
    },
    eGiftableProducts () {
      const { availableProducts, productIsNotEGiftable } = this
      return availableProducts.filter((product) => !productIsNotEGiftable(product))
    },
    eGiftableProductsIds () {
      return this.eGiftableProducts.map((product) => product.product_id)
    },
    filteredProducts () {
      const { availableProducts, hideNonEGiftableItems, eGiftableProducts } = this

      if (hideNonEGiftableItems) { return eGiftableProducts }
      return availableProducts
    },
    disableSelectAll () {
      const { selectedProductsIds, eGiftableProductsIds } = this
      return eGiftableProductsIds.every((id) => selectedProductsIds.includes(id))
    },
    disableClearAll () {
      const { selectedProductsIds, eGiftableProductsIds } = this
      return !eGiftableProductsIds.some((id) => selectedProductsIds.includes(id))
    },
  },
  watch: {
    selectedListId: 'fetchProductsFromList',
    availableLists: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val.length) {
          this.selectedListId = val.at(0)?.id ?? null
        }
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('newCampaign/setBreadcrumbs', [
        {
          routeName: 'CreateCampaignSource',
          title: 'Select gifts',
        },
        {
          routeName: 'CreateCampaignSourceFavorites',
          title: 'Your favorites',
        },
      ])
    })
  },
  methods: {
    ...mapMutations(['addNewSelectedProducts']),
    productIsNotEGiftable ({ product }) {
      const { send_as_egift, should_be_customized, min_qty } = product
      return !send_as_egift || should_be_customized || min_qty > 1
    },
    fetchProductsFromList () {
      const { selectedListId, abortController } = this
      if (selectedListId === null) { return }
      if (abortController) { abortController.abort() }

      this.$nextTick(() => {
        this.abortController = new AbortController()
        this.loading = true
        this.selectedProductsIds = []

        Api.get(
            `/customer/wishlists/${selectedListId}/items`,
            { signal: this.abortController.signal }
          )
          .then(({ data }) => (this.availableProducts = data))
          .finally(() => {
            this.loading = false
            this.abortController = null
          })
      })
    },
    addFilteredProducts () {
      const { eGiftableProductsIds, selectedProductsIds } = this
      this.selectedProductsIds = [...new Set([...selectedProductsIds, ...eGiftableProductsIds])]
    },
    removeFilteredProducts () {
      const { eGiftableProductsIds, selectedProductsIds } = this
      this.selectedProductsIds = selectedProductsIds.filter((id) => !eGiftableProductsIds.includes(id))
    },
    onAddProduct (productId) {
      this.selectedProductsIds.push(productId)
    },
    onRemoveProduct (productId) {
      const { selectedProductsIds } = this
      this.selectedProductsIds = selectedProductsIds.filter((id) => id !== productId)
    },
    goToNextPage () {
      const { selectedProductsIds, availableProducts, selectedListId } = this
      const ids = new Set(selectedProductsIds)
      const selectedProducts = availableProducts
        .filter((product) => ids.has(product.product_id))
        .map((product) => ({
          id: product.product_id,
          sourceId: selectedListId,
          isInventory: false,
          isCollection: false,
          image: product.product.image,
          name: product.product.name,
          price: product.product.price,
          url: product.link,
        }))

      if (!this.maxGiftsGuard(selectedProducts.length)) { return }

      this.addNewSelectedProducts(selectedProducts)
      this.$router.push({ name: 'CreateCampaignSourceReview', params: this.$route.params })
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-source-favorites {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 42px;

  &__products-actions {
    display: flex;
    flex-direction: column;
    gap: 36px;

    & > .common-toggle {
      display: none;
    }

    @media screen and (max-width: 430px) {
      gap: 20px;

      & > .common-toggle {
        display: inline-flex;
      }
    }
  }

  .create-campaign__products-actions {
    @media screen and (max-width: 430px) {
      display: none;
    }
  }

  &__products-filter > span {
    &:first-child::before {
      content: none;
    }
  }

  &::v-deep .v-slide-group__content {
    gap: 12px;
  }

  &::v-deep .favorites-list-item  {
    @media screen and (max-width: 430px) {
      padding: 8px 24px 8px 8px;
      height: 66px;

      &__image {
        width: 50px;
        height: 50px;
      }

      &__name {
        font-size: 16px;
        line-height: normal;
      }
    }
  }

  @media screen and (max-width: 430px) {
    & > h1 {
      padding: 0 16px;
    }
  }
}
</style>
