<template>
  <div class="create-campaign-source-inventory">
    <h1>Select gifts from your inventory</h1>

    <div class="create-campaign__products-wrapper">
      <div class="create-campaign__products-actions">
        <div class="create-campaign__products-filters">
          <common-input
            v-model="search"
            placeholder="Search gifts"
            prepend-inner-icon="mdi-magnify"
            height="36"
            clearable
            style="width: 250px"
            filter-style
          />

          <div class="create-campaign-source-inventory__actions-wrapper">
            <div class="create-campaign__products-buttons-group">
              <span
                role="button"
                :tabindex="disableSelectAll ? '-1' : '0'"
                @click="addFilteredProducts"
                @keyup.enter.space="addFilteredProducts"
                :aria-disabled="disableSelectAll ? 'true' : 'false'"
              >
                Select all
              </span>
              <span
                role="button"
                :tabindex="disableClearAll ? '-1' : '0'"
                @click="removeFilteredProducts"
                @keyup.enter.space="removeFilteredProducts"
                :aria-disabled="disableClearAll ? 'true' : 'false'"
              >
                Clear all
              </span>
            </div>

            <span role="button" @click="showLegendModal = true">
              Legend
            </span>
          </div>
        </div>

        <cg-button
          :disabled="!selectedProductsIds.length"
          @click="goToNextPage"
        >
          Add selected

          <template #append-icon>
            <div class="create-campaign__button-badge">
              {{ selectedProductsIds.length }}
            </div>
          </template>
        </cg-button>
      </div>

      <div class="create-campaign-source-inventory__legend-list">
        <legend-dot color="green">Units in stock</legend-dot>
        <legend-dot color="red">Out of stock</legend-dot>
        <legend-dot color="yellow">Units in production</legend-dot>
        <legend-dot color="grey">Units on hold</legend-dot>
      </div>

      <div class="create-campaign__products-grid">
        <product-card
          v-for="product in filteredProducts"
          :key="product.product_id"
          :id="product.product_id"
          :is-selected="selectedProductsIds.includes(product.product_id)"
          :image="product.image"
          :name="product.name"
          :price="product.price"
          :stock="product.stock"
          :variations="product.variations"
          :disabled="product.is_disabled || !!product.kit"
          :url="genInventoryProductUrl(product.product_url)"
          @addProduct="onAddProduct"
          @removeProduct="onRemoveProduct"
        />
      </div>
    </div>

    <div class="create-campaign__sticky-button">
      <cg-button
        :disabled="!selectedProductsIds.length"
        block
        @click="goToNextPage"
      >
        Add selected

        <template #append-icon>
          <div class="create-campaign__button-badge">
            {{ selectedProductsIds.length }}
          </div>
        </template>
      </cg-button>
    </div>

    <common-bottom-panel v-model="showLegendModal">
      <template #title>
        Color codes
      </template>

      <template>
        <legend-dot color="green">In Stock</legend-dot>
        <legend-dot color="red">Low inventory</legend-dot>
        <legend-dot color="yellow">In production</legend-dot>
        <legend-dot color="grey">Units on hold</legend-dot>
      </template>
    </common-bottom-panel>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import maxGiftsGuard from '@/components/createCampaign/mixins/maxGiftsGuard'
import genInventoryProductUrl from '@/components/myInventory/utils/genInventoryProductUrl'

import { CgButton } from '@corporategift/design-system'
import LegendDot from '@/components/myInventory/LegendDot'
import ProductCard from '@/components/createCampaign/ProductCard.vue'

const { mapState, mapMutations } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignSourceInventory',
  mixins: [maxGiftsGuard],
  components: {
    CgButton,
    LegendDot,
    ProductCard,
  },
  data: () => ({
    search: '',
    selectedProductsIds: [],
    showLegendModal: false,
  }),
  computed: {
    ...mapState(['source']),
    availableProducts () {
      return this.source.inventory ?? []
    },
    filteredProducts () {
      const { availableProducts, search } = this
      const textToSearch = (search ?? '').toLowerCase()

      return availableProducts
        .filter((item) => item.name.toLowerCase().indexOf(textToSearch) !== -1)
    },
    eGiftableFilteredProducts () {
      return this.filteredProducts
        .filter(({ kit }) => !kit)
        .filter(({ is_disabled }) => !is_disabled)
    },
    eGiftableFilteredProductsIds () {
      return this.eGiftableFilteredProducts.map((product) => product.product_id)
    },
    disableSelectAll () {
      const { selectedProductsIds, eGiftableFilteredProductsIds } = this
      return eGiftableFilteredProductsIds.every((id) => selectedProductsIds.includes(id))
    },
    disableClearAll () {
      const { selectedProductsIds, eGiftableFilteredProductsIds } = this
      return !eGiftableFilteredProductsIds.some((id) => selectedProductsIds.includes(id))
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('newCampaign/setBreadcrumbs', [
        {
          routeName: 'CreateCampaignSource',
          title: 'Select gifts',
        },
        {
          routeName: 'CreateCampaignSourceInventory',
          title: 'Your inventory',
        },
      ])
    })
  },
  methods: {
    ...mapMutations(['addNewSelectedProducts']),
    genInventoryProductUrl,
    addFilteredProducts () {
      const { eGiftableFilteredProductsIds, selectedProductsIds } = this
      this.selectedProductsIds = [...new Set([...selectedProductsIds, ...eGiftableFilteredProductsIds])]
    },
    removeFilteredProducts () {
      const { eGiftableFilteredProductsIds, selectedProductsIds } = this
      this.selectedProductsIds = selectedProductsIds.filter((id) => !eGiftableFilteredProductsIds.includes(id))
    },
    onAddProduct (productId) {
      this.selectedProductsIds.push(productId)
    },
    onRemoveProduct (productId) {
      const { selectedProductsIds } = this
      this.selectedProductsIds = selectedProductsIds.filter((id) => id !== productId)
    },
    goToNextPage () {
      const { selectedProductsIds, availableProducts, genInventoryProductUrl } = this
      const ids = new Set(selectedProductsIds)
      const selectedProducts = availableProducts
        .filter((product) => ids.has(product.product_id))
        .map((product) => ({
          id: product.product_id,
          sourceId: null,
          isInventory: true,
          isCollection: false,
          image: product.image,
          name: product.name,
          price: product.price,
          url: genInventoryProductUrl(product.product_url),
        }))

      if (!this.maxGiftsGuard(selectedProducts.length)) { return }

      this.addNewSelectedProducts(selectedProducts)
      this.$router.push({ name: 'CreateCampaignSourceReview', params: this.$route.params })
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign__products-wrapper {
  gap: 30px;
}

.create-campaign-source-inventory {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 42px;

  &__actions-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: space-between;

    & > span {
      display: none;
      color: #6E50FF;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      font-family: 'Lato', sans-serif;
    }

    @media screen and (max-width: 430px) {
      width: 100%;

      & > span {
        display: initial;
      }
    }
  }

  &__legend-list {
    gap: 20px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 430px) {
      display: none;
    }
  }
}
</style>
