<template>
  <cg-form ref="form" class="create-campaign-review-card">
    <h2>Advanced options</h2>

    <hr>

    <div class="review-campaign-advanced-options__input-wrapper">
      <cg-input
        v-model="emailSubjectLine"
        :validation="[checkIfStringContainsNotAllowedTag]"
      >
        <template #label>
          <div class="review-campaign-advanced-options__input-label-with-tooltip">
            Email subject line

            <common-tooltip
              :max-width="null"
              :z-index="8"
              :activator-size="20"
              activator-color="#62646A"
            >
              <img alt="" :src="getProductImageBySlug('campaigns_edit_email_subject')" />
            </common-tooltip>

            <button class="ml-auto" @click.prevent="resetEmailSubjectLine">
              Reset
            </button>
          </div>
        </template>
      </cg-input>
    </div>

    <div class="review-campaign-advanced-options__input-wrapper">
      <cg-input v-model="videoGreeting" :disabled="!userIsCgElite">
        <template #label>
          <div class="review-campaign-advanced-options__input-label-wrapper">
            <div class="review-campaign-advanced-options__input-label-with-tooltip">
              Video greeting URL (optional)

              <common-tooltip
                :max-width="null"
                :z-index="8"
                :activator-size="20"
                activator-color="#62646A"
              >
                <img alt="" :src="getProductImageBySlug('campaigns_video_greeting_link')" />
              </common-tooltip>
            </div>

            <button v-if="videoGreeting" @click.prevent="previewVideoURL">
              Preview
            </button>

            <create-campaign-cg-elite-badge v-if="!userIsCgElite" />
          </div>
        </template>
      </cg-input>
    </div>

    <cg-checkbox v-model="allowExchange">
      Allow recipients to upgrade the gift

      <div class="ml-2">
        <common-tooltip
          :max-width="360"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_allow_to_exchange') }}
        </common-tooltip>
      </div>
    </cg-checkbox>

    <span
      role="button"
      class="review-campaign-advanced-options__options-button"
      @click="showMoreOptions = !showMoreOptions"
    >
      {{ showMoreOptions ? 'Less options' : 'More options' }}
    </span>

    <v-expand-transition>
      <div v-show="showMoreOptions" class="review-campaign-advanced-options__more-options">
        <div class="review-campaign-advanced-options__expiration-input">
          <label for="egift-expiration-time">eGift will expire after</label>
          <div>
            <cg-input
              v-model.number="expirationDays"
              type="number"
              min="1"
              max="400"
              step="1"
              :width="80"
              :validation="[required(), campaignExpirationDaysValidation]"
              id="egift-expiration-time"
            />

            days
          </div>
        </div>

        <cg-checkbox v-model="addButtonLink">
          Add a call-to-action button after redemption

          <div class="ml-2">
            <common-tooltip
              :max-width="360"
              :z-index="8"
              :activator-size="20"
              activator-color="#62646A"
            >
              You can add any URL to redirect your recipients
            </common-tooltip>
          </div>
        </cg-checkbox>

        <v-expand-transition>
          <div v-if="addButtonLink" class="review-campaign-advanced-options__more-options">
            <cg-input
              v-model="buttonLinkLabel"
              placeholder="E.g. Book a meeting"
              :validation="[required()]"
            >
              <template #label>Button label</template>
            </cg-input>

            <cg-input v-model="buttonLinkUrl" :validation="[required()]">
              <template #label>Button URL</template>
            </cg-input>
          </div>
        </v-expand-transition>

        <cg-checkbox v-model="disableThankYouNotes">
          <div class="review-campaign-advanced-options__thank-you-checkbox">
            Don’t allow recipient <b>thank you notes</b>
          </div>
        </cg-checkbox>

        <cg-checkbox v-model="disableReminderEmails" class="mb-0">
          Disable reminder emails to recipients

          <div class="ml-2">
            <common-tooltip
              :max-width="360"
              :z-index="8"
              :activator-size="20"
              activator-color="#62646A"
            >
              {{ getTooltipTextBySlug('campaigns_disable_reminder_emails') }}
            </common-tooltip>
          </div>
        </cg-checkbox>
      </div>
    </v-expand-transition>
  </cg-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import userName from '@/mixins/userName'
import companyName from '@/mixins/companyName'
import userIsCgElite from '@/mixins/userIsCgElite'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import getProductImageBySlug from '@/mixins/getProductImageBySlug'
import checkIfStringContainsNotAllowedTag from '@/components/createCampaign/mixins/checkIfStringContainsNotAllowedTag'

import { CgInput, CgCheckbox, CgForm } from '@corporategift/design-system'
import CreateCampaignCgEliteBadge from '@/components/createCampaign/CreateCampaignCgEliteBadge.vue'

const { mapState, mapMutations } = createNamespacedHelpers('newCampaign')
import { required } from '@corporategift/design-system/validations'

import campaignExpirationDaysValidation from '@/utils/campaignExpirationDaysValidation'

export default {
  name: 'CreateCampaignReviewCardAdvancedOptions',
  mixins: [
    userName,
    companyName,
    userIsCgElite,
    getTooltipTextBySlug,
    getProductImageBySlug,
    checkIfStringContainsNotAllowedTag,
  ],
  components: {
    CgForm,
    CgInput,
    CgCheckbox,
    CreateCampaignCgEliteBadge,
  },
  data: () => ({
    showMoreOptions: false,
  }),
  computed: {
    ...mapState(['form']),
    expirationDays: {
      get () { return this.form.advancedOptions.expirationDays },
      set (val) { this.setExpirationDays(val) },
    },
    emailSubjectLine: {
      get () { return this.form.advancedOptions.emailSubjectLine },
      set (val) { this.setEmailSubjectLine(val) },
    },
    videoGreeting: {
      get () { return this.form.advancedOptions.videoGreeting },
      set (val) { this.setVideoGreeting(val) },
    },
    allowExchange: {
      get () { return this.form.advancedOptions.allowExchange },
      set (val) { this.setAllowExchange(val) },
    },
    disableThankYouNotes: {
      get () { return this.form.advancedOptions.disableThankYouNotes },
      set (val) { this.setDisableThankYouNotes(val) },
    },
    disableReminderEmails: {
      get () { return this.form.advancedOptions.disableReminderEmails },
      set (val) { this.setDisableReminderEmails(val) },
    },
    addButtonLink: {
      get () { return this.form.advancedOptions.successButton.addButton },
      set (val) { this.setAddSuccessButton(val) },
    },
    buttonLinkUrl: {
      get () { return this.form.advancedOptions.successButton.buttonURL },
      set (val) { this.setSuccessButtonURL(val) },
    },
    buttonLinkLabel: {
      get () { return this.form.advancedOptions.successButton.buttonLabel },
      set (val) { this.setSuccessButtonLabel(val) },
    },
    defaultEmailSubjectLine () {
      const { companyName, userIsCgElite, userFullName } = this;

      if (userIsCgElite && companyName) { return `${companyName} - Sent You a Gift!` }
      if (userFullName) { return `${userFullName} - Sent You a Gift!` }
      return 'You received a gift!'
    }
  },
  created () {
    const { addButtonLink, disableReminderEmails, disableThankYouNotes } = this
    const flagsToCheck = [addButtonLink, disableReminderEmails, disableThankYouNotes]
    // if any of the flags is checked show more options
    this.showMoreOptions = flagsToCheck.some((val) => val)

    this.setDefaultEmailSubjectLine();
  },
  methods: {
    required,
    campaignExpirationDaysValidation,
    ...mapMutations([
      'setAllowExchange',
      'setVideoGreeting',
      'setExpirationDays',
      'setAddSuccessButton',
      'setEmailSubjectLine',
      'setSuccessButtonURL',
      'setSuccessButtonLabel',
      'setDisableThankYouNotes',
      'setDisableReminderEmails',
    ]),
    setDefaultEmailSubjectLine () {
      const { emailSubjectLine, defaultEmailSubjectLine } = this;
      if (emailSubjectLine === null) { this.emailSubjectLine = defaultEmailSubjectLine; }
    },
    previewVideoURL () {
      this.$store.dispatch('app/handleVideoModal', {
        show: true,
        url: this.videoGreeting,
      })
    },
    resetEmailSubjectLine () {
      this.emailSubjectLine = this.defaultEmailSubjectLine
    },
    validate () {
      const result = this.$refs.form.validate();

      if (!result) { this.showMoreOptions = true }
      return result;
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-review-card {
  background-color: #F6F5FF;
  color: #222325;
}

.review-campaign-advanced-options {
  &__input-wrapper {
    width: 100%;
  }

  &__input-label-wrapper {
    gap: 4px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #222325;
    text-align: left;

    & > button {
      color: #6E50FF;
      font-family: "Lato-Regular", sans-serif;
      font-weight: 400;
    }
  }

  &__options-button {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.28px;
    color: #6E50FF;
  }

  &__expiration-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 24px;

    & > label {
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
      text-align: left;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  &__more-options {
    display: flex;
    width: 100%;
    flex-direction: column;

    & > .cg-checkbox,
    & > .cg-input {
      margin-bottom: 24px
    }

  }

  &__thank-you-checkbox {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    & > b {
      font-family: 'Lato-Bold', sans-serif;
      font-style: italic;
      font-weight: 700;
    }
  }

  &__input-label-with-tooltip {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 20px;
    gap: 8px;

    & > button {
      color: #6E50FF;
      font-family: "Lato-Regular", sans-serif;
      font-weight: 400;
    }
  }
}
</style>
