<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    v-click-outside="clickOutsideHandler"
    app
    temporary
    stateless
    right
    width="900px"
  >
    <v-alert color="#FCEBA4" tile class="missing-info-alert mb-0" v-if="!productRefillData.has_all_information">
      <template #prepend>
        <icons-info :width="32" :height="20" class="mr-3" />
      </template>

      We're missing some info for this inventory product. Please contact your Customer Success Manager to update your info and enable refill.
    </v-alert>

    <unsaved-changes-alert
      v-if="userHasUnsavedChanges"
      @saveChanges="userHasUnsavedChanges = false"
      @closePanel="show = false"
    >
      <template #default>
        You haven't completed your order - are you sure you want to exit?
      </template>
      <template #save>
        Stay
      </template>
    </unsaved-changes-alert>

    <close-panel-button
      @close="tryClosePanel()"
      :text="showSuccessPage ? 'Close' : selectedKitName ? selectedKitName : 'My inventory'"
    />

    <common-loader v-if="loading" style="max-height: 800px" />

    <v-container class="px-9 py-0 refill-panel" v-if="!loading && product && show && !showSuccessPage">
      <v-row>
        <v-col cols="12">
          <h2 class="f30 lato-light font-weight-light">
            Refill inventory
          </h2>
        </v-col>
        <v-col cols="4">
          <v-row no-gutters>
            <v-col cols="12">
              <product-image
                :altText="product.name"
                :image="product.image"
                :centerHorizontal="false"
                width="224"
                height="224"
              />
            </v-col>
            <v-col cols="12" class="pt-4">
              <h1 class="f16 product-name text-left">
                {{ product.name }}
              </h1>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row no-gutters v-if="customizationData.length">
            <v-col cols="12" class="subtitle pb-3">
              Customization
            </v-col>
            <v-col cols="12" class="customization-info">
              <v-row no-gutters>
                <template v-for="(customizationInfo, index) in customizationData">
                  <v-col cols="12" :key="index" v-if="customizationInfo.option_type === 'personalize_type_logo'">
                    <logo-preview :logoUrl="customizationInfo.value" />
                  </v-col>

                  <v-col
                    cols="12"
                    :key="index"
                    style="white-space: nowrap;"
                    v-else-if="customizationValueIsALink(customizationInfo.value)"
                  >
                    {{ customizationInfo.label }}:
                    <a :href="customizationInfo.value" class="refill-panel__link" download>Download image file</a>

                  </v-col>

                  <v-col cols="12" :key="index" v-else>
                    {{ customizationInfo.label }}: {{ customizationInfo.value }}
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <refill-price-table
            v-if="productRefillData.prices && productRefillData.prices.length > 1"
            :prices="productRefillData.prices"
          />
        </v-col>
      </v-row>

      <v-divider class="my-6" />
      <!-- many variations -->
      <template v-if="productRefillData.has_all_information">
        <template v-if="product.variations && product.variations.short.length">
          <v-row no-gutters>
            <v-col cols="12" class="bold-text">
              How many would you like of each size?
            </v-col>
            <v-col cols="12" class="sub-text pt-1" v-if="minQty">
              Minimum quantity of this item is {{ minQty }}
            </v-col>

            <v-col cols="12" class="pt-5">
              <v-row dense class="align-end g20">
                <template v-for="(variant, index) in sortedProductVariations">
                  <v-col :key="index" class="flex-shrink-1 flex-grow-0">
                    <refill-input
                      v-model="refillInputs[index]"
                      :marketplaceProductQty="variant.marketplace_product_qty"
                      :missingInfo="!variant.has_all_information"
                      :label="variant.code"
                      :id="variant.item_id"
                      :outOfStock="variant.is_out_of_stock && variant.has_all_information"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <product-form-without-options
            v-model="refillInputs[0]"
            :minQty="minQty"
            :marketplaceProductQty="product.stock.marketplace_product_qty"
            :entityId="product.item_id"
            class="pt-3"
          />
        </template>

        <v-row>
          <v-col cols="12">
            <refill-subtotal
              :loading="loadingProductSettings"
              :price="prodSettingsData.price_breakdown.unit_cost"
              :shippingPrice="prodSettingsData.price_breakdown.shipping_to_warehouse"
              :shippingPriceFull="prodSettingsData.shipping_to_warehouse_full"
              :storagePrice="prodSettingsData.price_breakdown.storage"
              :pickAndPackPrice="prodSettingsData.price_breakdown.pick_and_pack"
              :shippingToRecipPrice="prodSettingsData.price_breakdown.shipping_to_recipient"
              :tax="prodSettingsData.price_breakdown.tax"
              :customizationFee="prodSettingsData.price_breakdown.options_cost"
              :setupFee="prodSettingsData.setup_fee"
              :totalQty="totalQty"
              :minQty="minQty"
              :overwriteSubtotal="true"
              :customSubtotal="prodSettingsData.subtotal"
              :variantsPrices="prodSettingsData.items"
              :hasVariants="!!(product.variations && product.variations.short.length)"
              :totalPerUnit="prodSettingsData.total_per_unit"
              @subtotalChange="(val) => subtotal = val"
            />
          </v-col>
          <!-- MOVED TO ANOTHER PHASE -->
          <v-col cols="12" v-if="false">
            <low-quantity-alert
              :itemId="product.item_id"
              :enabled.sync="product.notify_for_qty_below.enabled"
              :qty.sync="product.notify_for_qty_below.value"
            />
          </v-col>
        </v-row>

        <v-divider class="my-6" />

        <v-row no-gutters>
          <v-col cols="12" class="bold-text pb-8">
            Choose payment method:
          </v-col>

          <v-col cols="12">
            <payment-module
              :paymentMethod.sync="payment.method"
              :paymentData.sync="payment.paymentData"
              :amount="subtotal"
              ref="paymentModule"
            />
          </v-col>
          <!-- TODO move to another component -->
          <v-col cols="12" class="pt-10" v-if="productHasArtProof">
            <common-checkbox v-model="proofOfArtConfirmed">
              By refilling this order, you confirm approval of the original
              <a :href="productArtProofLink" target="_blank" class="green2 pl-1 pointer">
                art proof
              </a>
            </common-checkbox>
          </v-col>
        </v-row>

        <common-button
          style="width: 192px"
          class="my-9"
          @click="placeOrder"
          :height="45"
          :disabled="disableCTAButton"
          :loading="sendingData"
        >
          Place order
        </common-button>

        <div class="refill-panel__error mb-9">
          <template v-for="(error, index) in refillInputsErrors">
            <div :key="index" class="pb-2">
              {{ error }}
            </div>
          </template>

          <div v-if="userDidNotSelectAnything">
            No units to refill have been entered
          </div>
        </div>
      </template>
    </v-container>
    <!-- TODO move to another component? -->
    <!-- SUCCESS MESSAGE/PAGE -->
    <v-container
      v-if="!loading && product && show && showSuccessPage"
      class="refill-panel-success-page"
    >
      <v-row justify="center">
        <v-col cols="12">
          <v-row class="text-center">
            <v-col cols="12" class="d-flex justify-center">
              <icons-thumb-up width="32" height="32" />
            </v-col>
            <v-col cols="12" class="refill-panel-success-page__thank-you">
              Thank you!
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pt-8">
          <v-row class="text-center">
            <v-col cols="12" class="refill-panel-success-page__order-number">
              Your order number is {{ successResponse.order_number }}
            </v-col>
            <v-col cols="12" class="refill-panel-success-page__order-info">
              You will receive a confirmation email with your order details and a link to track it progress.
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10" class="pt-14">
          <v-row no-gutters class="refill-panel-success-page__order-approval-info">
            <v-col cols="12" class="pb-2">
              <icons-warning height="30" width="30" />
            </v-col>
            <v-col cols="12" class="pb-4">
              Inventory refill orders will be executed only after approval by our production team, therefore turn around time may vary.
            </v-col>
            <v-col cols="12">
              Once approved, the items are shipped to our Inventory warehouse. <br/>
              If you need to ship to a diffrent address please
              <a href="https://corporategift.com/contact-us/" target="_blank" class="refill-panel__link">contact our customer service </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'
import PaymentType from '@/components/payment/paymentModule/utils/PaymentType'
import getPaymentBody from '@/components/payment/paymentModule/utils/getPaymentBody'

import ProductImage from '../ProductImage'
import UnsavedChangesAlert from './common/UnsavedChangesAlert'
import ProductFormWithoutOptions from '../RefillPanel/ProductFormWithoutOptions'
import LowQuantityAlert from '../RefillPanel/LowQuantityAlert'
import RefillSubtotal from '../RefillPanel/RefillSubtotal'
import RefillPriceTable from '../RefillPanel/RefillPriceTable'
import RefillInput from '../RefillPanel/RefillInput'
import LogoPreview from '../RefillPanel/LogoPreview'
import PaymentModule from '@/components/payment/PaymentModule.vue'

import panelShow from './mixins/panelShow'

import { purchase, destinationDeclined } from '@/plugins/googleTagManager'

import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('tooltips')

export default {
  name: 'RefillPanel',
  components: {
    RefillInput,
    LogoPreview,
    ProductImage,
    RefillSubtotal,
    PaymentModule,
    LowQuantityAlert,
    RefillPriceTable,
    UnsavedChangesAlert,
    ProductFormWithoutOptions
  },
  mixins: [panelShow],
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    selectedKitName: {
      type: String,
      required: false,
      default: null
    },
    product: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    loading: false,
    sendingData: false,
    prodSettingsData: {},
    productRefillData: {},
    userDidNotSelectAnything: false,
    userHasUnsavedChanges: false,
    loadingProductSettings: false,
    refillInputs: [],
    subtotal: 0,
    payment: {
      method: PaymentType.BE,
      paymentData: null,
    },
    refillInputsErrors: [],
    proofOfArtConfirmed: false,
    successResponse: null,
    showSuccessPage: false,
    productSettingsPromises: []
  }),
  computed: {
    ...mapGetters(['getTooltipTextById']),
    minQty () {
      return this.productRefillData?.min_qty || 0
    },
    totalQty () {
      const total = this.refillInputs.reduce((acc, input) => acc + input.qty, 0)
      return total
    },
    customizationData () {
      return this.productRefillData?.customization?.personalizable || []
    },
    productHasArtProof () {
      return !!this.productRefillData?.art_proof || false
    },
    productArtProofLink () {
      return this.productRefillData?.art_proof || null
    },
    disableCTAButton () {
      const { minQty, totalQty, proofOfArtConfirmed, productHasArtProof } = this

      return productHasArtProof
        ? (totalQty < minQty || !proofOfArtConfirmed)
        : (totalQty < minQty)
    },
    sortedProductVariations () {
      const variations = this.product?.variations?.short ?? []
      const sizes = {
        xs: 100,
        s: 200,
        small: 200,
        m: 300,
        medium: 300,
        l: 400,
        large: 400,
        xl: 500,
        xxl: 600,
        '2xl': 600,
        xxxl: 700,
        '3xl': 700,
      };

      const takeVariationSize = (variationLabel) =>
        variationLabel?.match(/\b(XS|S|Small|M|Medium|L|Large|XL|2XL|XXL|3XL|XXXL)\b/i)?.[0] ?? undefined

      return variations.sort((a, b) => {
        const aCode = takeVariationSize(a.code)
        const bCode = takeVariationSize(b.code)

        if (!aCode) { return 1 }
        if (!bCode) { return -1 }

        const parsedACode = aCode.toLowerCase()
        const parsedBCode = bCode.toLowerCase()

        return sizes[parsedACode] - sizes[parsedBCode]
      })
    },
    userEmail () {
      return this.$store.state?.header?.headerData?.customerEmail ?? null
    },
    userId () {
      return this.$store.state?.header?.headerData?.customerId ?? null
    },
    paymentMethod () {
      return this.payment.method
    },
    googleAnalyticsItems () {
      const { product, subtotal, totalQty } = this

      return [{
        'item_id': product?.item_id ?? product?.product_id,
        'item_name': product.name,
        'item_category': 'inventory',
        'price': subtotal?.toFixed(2),
        'quantity': totalQty || 1,
        'currency': 'USD',
      }]
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.loading = true

        const productRefill = Api.get(`customer/purchased-inventory/item/${this.product.item_id}/refill-options`)
          .then(({ data }) => data)
          .catch(() => ({
            customization: {},
            prices: [],
            min_qty: null,
            subtotal: null,
            notify_for_qty_below: {
              enabled: false,
              qty: null
            },
            has_all_information: false
          }))

        this.setRefillInputsData().then(() => {
          const productSettings = this.getProductSettings()
          Promise.all([productSettings, productRefill]).then(values => {
            this.prodSettingsData = values[0]
            this.productRefillData = values[1]

            if (this.minQty && this.refillInputs[0]) {
              this.refillInputs[0].qty = this.minQty
            }
            this.loading = false
          })
        })
      }
      if (!val) {
        this.$emit('setSelectedKitName', null)
        Object.assign(this.$data, this.$options.data())
      }
    },
    totalQty: debounce(function (val) {
      this.loadingProductSettings = true
      this.productSettingsPromises.push(this.getProductSettings(val))
      // TODO better debug
      Promise.all(this.productSettingsPromises).then((response) => {
        this.prodSettingsData = response?.at(-1) ?? {}
      }).finally(() => {
        this.loadingProductSettings = false
        this.productSettingsPromises = []
      })
    }, 700),
    paymentMethod: function () {
      this.refillInputsErrors = []
    }
  },
  methods: {
    async getProductSettings () {
      const items = this.refillInputs
        .filter((input) => input.qty)
        .map((item) => ({
          item_id: item.entity_id,
          qty: item?.qty,
        }))

      const qtyCount = items.reduce((acc, { qty }) => acc += qty, 0)

      const emptyResponse = {
        items: [],
        price_breakdown: {
          options_cost: 0,
          pick_and_pack: 0,
          shipping_to_recipient: 0,
          shipping_to_warehouse: 0,
          storage: 0,
          tax: 0,
          unit_cost: 0,
        },
        setup_fee: 0,
        shipping_to_warehouse_full: 0,
        subtotal: 0,
        total_per_unit: 0,
      }

      if (qtyCount <= 0) {
        return Promise.resolve(emptyResponse)
      }

      return Api.post(`customer/purchased-inventory/item/${this.product.item_id}/refill-calculate-cost`, {
        items
      })
        .then((data) => data)
        .catch(() => emptyResponse)
    },
    async setRefillInputsData () {
      const { product } = this
      if (product.variations && product.variations.short.length) {
        product.variations.short.forEach((variant) => {
          if (variant.has_all_information) {
            this.refillInputs.push({
              entity_id: variant.item_id,
              qty: null
            })
          }
        })
      } else {
        this.refillInputs.push({
          entity_id: product.item_id,
          qty: null
        })
      }
    },
    customizationValueIsALink (customizationValue) {
      let url
      try {
        url = new URL(customizationValue)
      } catch (_) {
        return false
      }

      return ['http:', 'https:'].includes(url.protocol)
    },
    tryClosePanel () {
      const { payment, refillInputs, showSuccessPage } = this
      const refillsWithQty = refillInputs.filter(input => input.qty > 0)
      if (showSuccessPage) {
        this.$emit('fetchInventoryList')
        this.show = false
      }

      if (refillsWithQty.length > 0 || payment.method !== PaymentType.BE) {
        this.userHasUnsavedChanges = true
      } else {
        this.show = false
      }
    },
    clickOutsideHandler (e) {
      if (e.target.className === 'v-overlay__scrim') {
        this.tryClosePanel()
      }
    },
    placeOrder () {
      this.userDidNotSelectAnything = false
      this.refillInputsErrors = []
      if (!this.$refs.paymentModule.validate()) return

      const { refillInputs, payment, product: { item_id: itemId } } = this

      const refillInputsWithQty = refillInputs.map((input, index) => {
        if (input.qty) {
          return ({
            qty: input.qty,
            entity_id: input.entity_id,
            index
          })
        }
      }).filter(Boolean) // dirty hack to remove undefined from array

      if (!refillInputsWithQty.length) {
        this.userDidNotSelectAnything = true
        return
      }

      this.userDidNotSelectAnything = false

      const paymentBody = getPaymentBody(payment)
      const data = {
        ...paymentBody,
        payment_method: paymentBody?.method,
      }

      data.items = refillInputsWithQty.map((item) => ({
        qty: item.qty,
        item_id: item.entity_id
      }))

      this.sendingData = true
      Api.post(`customer/purchased-inventory/item/${itemId}/refill`, data)
        .then((response) => {
          if (response.success) {
            this.$cgToast.successBold('The inventory refill order has been submitted successfully!')
            this.$emit('fetchInventoryList')
            this.successResponse = response.data
            this.showSuccessPage = true

            let GAPaymentMethod = payment.method

            if (payment.method === PaymentType.CC) GAPaymentMethod = 'credit card'
            if (payment.method === PaymentType.ACH) GAPaymentMethod = 'ach'
            if (payment.method === PaymentType.BE) GAPaymentMethod = 'cg credit'
            if (payment.method === PaymentType.PO) GAPaymentMethod = 'purchase order'

            purchase({
              transactionId: response?.data?.order_number,
              value: this.subtotal?.toFixed(2),
              paymentType: GAPaymentMethod,
              checkoutType: 'inventory refill',
              email: this.userEmail,
              userId: this.userId,
              parentAccount: null,
              items: this.googleAnalyticsItems ?? [],
            })
          } else {
            this.refillInputsErrors = response?.message ? [response?.message] : []

            destinationDeclined({
              action: this.refillInputsErrors?.join(', ') ||
                'An unexpected error occurred, please try again later or contact our support'
            })
          }
        })
        .catch(({ response: { data: { errors } } }) => {
          this.refillInputsErrors = errors || []

          destinationDeclined({
            action: this.refillInputsErrors?.join(', ') ||
              'An unexpected error occurred, please try again later or contact our support'
          })
        })
        .finally(() => {
          this.sendingData = false
        })
    }
  }
}
</script>

<style lang="scss">
.refill-panel {
  .v-input {
    &__slot {
      padding: 0 4px;
      .v-text-field__suffix {
        font-family: 'Lato-Italic', sans-serif;
        font-size: 13px;
        line-height: 16px;
        color: #a1a1a1;
        height: 20px;
        display: flex;
        align-items: flex-end;
      }

      &::before {
        border-image: none !important;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .panel-payment {
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
.refill-panel {
  .product-name, .bold-text, .subtitle {
    font-family: 'Lato-Bold', sans-serif !important;
  }

  .sub-text, &__error {
    font-family: 'Lato-Italic', sans-serif !important;
  }

  &__checkbox-text, .customization-info {
    font-family: 'Lato-Regular', sans-serif !important;
  }

  .product-name, .customization-info {
    letter-spacing: 0;
  }

  .bold-text, .sub-text, &__error, &__checkbox-text {
    font-size: 15px;
  }

  .product-name, .sub-text, &__error, &__checkbox-text {
    line-height: 18px;
  }

  .bold-text {
    line-height: 22px;
  }

  .product-name, .customization-info, .bold-text, &__checkbox-text {
    color: #000;
  }

  .sub-text {
    color: #a1a1a1;
  }

  &__error {
    color: #d04d55;
  }

  .v-input {
    display: inline-block;

    &__slot {
      padding: 0 4px;
      .v-text-field__suffix {
        font-family: 'Lato-Italic', sans-serif;
        font-size: 13px;
        line-height: 16px;
        color: #a1a1a1;
        height: 20px;
        display: flex;
        align-items: flex-end;
      }

      &::before {
        border-image: none !important;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .subtitle {
    font-size: 11px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    line-height: 13px;
  }

  .customization-info {
    font-size: 15px;
    line-height: 26px;
  }

  .missing-info-alert {
    font-size: 16px;
    color: #222325;
    line-height: 19px;
    font-family: 'Lato-Regular', sans-serif !important;
  }

  &-success-page {
    &__thank-you {
      font-size: 40px;
      color: #000;
      line-height: 48px;
      font-family: 'Lato-Light', sans-serif !important;
    }

    &__order-number {
      font-size: 25px;
      line-height: 30px;
      color: #000;
      font-family: 'Lato-Regular', sans-serif !important;
    }

    &__order-info {
      font-size: 14px;
      line-height: 17px;
      color: #9F9F9F;
      font-family: 'Lato-Italic', sans-serif !important;
    }

    &__order-approval-info {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      padding: 20px;
      font-family: 'Lato-Regular', sans-serif !important;
      background-color: #F6F6F6;
      color: #000;
    }
  }

  &__link {
    color: #219358 !important;
  }
}
</style>
