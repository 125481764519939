<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    v-click-outside="clickOutsideHandler"
    app
    temporary
    stateless
    right
    width="900px"
  >
    <v-alert color="#FCEBA4" tile class="missing-info-alert mb-0" v-if="!productHasAllInformation">
      <template #prepend>
        <icons-info :width="32" :height="20" class="mr-3" />
      </template>

      We’re missing some info for this inventory product, therefore we cannot change product <b>auto-refill</b> settings
      Please contact your Customer Success Manager to update your info and enable auto-refill.
    </v-alert>

    <unsaved-changes-alert
      v-if="userHasUnsavedChanges"
      @saveChanges="userHasUnsavedChanges = false"
      @closePanel="show = false"
    >
      <template #default>
        You haven't saved your changes. Exit anyway?
      </template>
      <template #save>
        Stay
      </template>
    </unsaved-changes-alert>

    <close-panel-button
      @close="tryClosePanel()"
      text="My inventory"
    />

    <common-loader v-if="loading" style="max-height: 800px" />

    <v-container class="px-9 py-0 auto-refill-panel" v-if="!loading && product && show">
      <v-row class="justify-space-between">
        <v-col cols="12">
          <h2 class="f30 lato-light font-weight-light black1">
            Product settings
          </h2>
        </v-col>
        <v-col cols="4">
          <product-image
            :altText="product.name"
            :image="product.image"
            :centerHorizontal="false"
            width="231"
            height="231"
          />
        </v-col>
        <v-col cols="4" v-if="productRefillData.prices && productRefillData.prices.length > 1">
          <refill-price-table :prices="productRefillData.prices" />
        </v-col>
        <v-col cols="8" class="align-self-center auto-refill-panel__product auto-refill-panel__product--name">
          {{ product.name }}

          <div class="d-flex g10 pt-2">
            <update-status-sheet
              color="#D2F5F3"
              textColor="#004642"
              v-for="(variantOption, index) in Object.keys(productVariantsOptions)"
              :key="index"
            >
              {{ productVariantsOptions[variantOption].length }} {{ variantOption }} options
            </update-status-sheet>
          </div>
        </v-col>

        <v-col cols="12">
          <v-divider class="mb-6" />
        </v-col>
        <v-col cols="12">
          <common-checkbox v-model="enableLowQtyAlert">
            Enable low quantity alert
          </common-checkbox>

          <div class="d-flex g10 align-center pt-5">
            Notify me by email when available quantity drops to

            <common-input
              v-model.number="lowQtyAlertValue"
              type="number"
              :disabled="!enableLowQtyAlert"
              style="max-width: 100px"
              :height="36"
              suffix="Units"
            />

            <span class="red1 f15 lato-italic" v-if="productHasVariants">
              * Calculated for each variant (e.g. size)
            </span>
          </div>
        </v-col>

        <v-col cols="12">
          <v-divider class="mb-6 mt-2" />
        </v-col>

        <v-col cols="12" class="d-flex g20">
          <settings-panel-variants-option-item
            v-model="selectedMultiVariantOption"
            item-value="on_hold"
            :disabled="!productHasAllInformation"
            :items="onHoldStrategyInfo"
            @showExampleVideo="exampleVideo = { show: true, url: 'https://www.loom.com/embed/54370d34b34d43ccab7acfa859045ff3' }"
          >
            <template #title>
              {{ productHasVariants
                ? 'Ensure your recipient can only select in-stock sizes/variants'
                : 'Ensure your recipient can get this item only when it’s in-stock'
              }}
            </template>
          </settings-panel-variants-option-item>
          <popper
            :disabled="productHasAllInformation"
            :options="{
              placement: 'top',
              modifiers: { offset: { offset: '0,6px' } }
            }"
          >
            <div class="popper px-4 py-2 text-left" style="max-width: 300px">
              We’re missing some info for this inventory product, therefore we cannot change product
              <b>back-order</b> settings. <br> Please contact your Customer Success Manager to
              update your info and enable editing.
            </div>

            <settings-panel-variants-option-item
              v-model="selectedMultiVariantOption"
              item-value="back_order"
              slot="reference"
              :disabled="!productHasAllInformation"
              :items="backOrderStrategyInfo"
              @showExampleVideo="exampleVideo = { show: true, url: 'https://www.loom.com/embed/54370d34b34d43ccab7acfa859045ff3' }"
            >
              <template #title>
                {{ productHasVariants
                  ? "Ensure your recipient can choose their preferred size/variant, even if it's out of stock"
                  : "Ensure your recipient can choose this item even if it's out of stock"
                }}
                
              </template>
            </settings-panel-variants-option-item>
          </popper>
        </v-col>
        <v-col cols="12">
          <v-divider class="my-4" />
        </v-col>

        <v-col cols="12" class="pb-7 d-flex">
          <common-checkbox v-model="autoRefill" :disabled="disableAutoRefillCheckbox || !productHasAllInformation">
            Enable auto-refill
          </common-checkbox>
        </v-col>

        <v-col cols="12">
          <v-row no-gutters class="align-center">
            <v-col cols="4" class="mr-4">
              <label class="black1" for="stock-drop">
                When product stock drops to:
              </label>
              <common-input
                v-model.number="refillWhenAmountEquals"
                ref="when-refill-drops-to-input"
                @blur="negativeNumberHandler('refillWhenAmountEquals')"
                :rules="[rules.requiredNumber]"
                :disabled="!autoRefill"
                :validate-on-blur="false"
                type="number"
                min="0"
                suffix="Units"
                id="stock-drop"
                class="v-input--error-hint"
                :hide-details="false"
                :messages="productHasVariants ? 'Calculated for each variant (e.g. size)' : null"
                required
              />
            </v-col>
            <v-col cols="4">
              <label class="black1" for="refill-amount"> Refill amount: </label>
              <common-input
                v-model.number="refillAmount"
                ref="refill-amount-input"
                @blur="negativeNumberHandler('refillAmount', { min: productRefillData.min_qty || 1 })"
                :rules="[rules.required, rules.minQty(productRefillData.min_qty || 1)]"
                :disabled="!autoRefill"
                :validate-on-blur="false"
                type="number"
                :min="productRefillData.min_qty || 1"
                suffix="Units"
                id="refill-amount"
                :hide-details="false"
                persistent-hint
                :hint="
                  productRefillData.min_qty > 1
                  ? `Min. qty for this product: ${productRefillData.min_qty}`
                  : null
                "
                required
              />
            </v-col>
          </v-row>
        </v-col>
        <template v-if="productHasAllInformation">
          <v-col cols="12">
            <v-divider class="mb-10 mt-5" />
          </v-col>

          <v-col cols="12">
            <refill-subtotal
              :loading="loadingProductSettings"
              :price="prodSettingsData.price_breakdown.unit_cost || 0"
              :shippingPrice="prodSettingsData.price_breakdown.shipping_to_warehouse || 0"
              :storagePrice="prodSettingsData.price_breakdown.storage || 0"
              :pickAndPackPrice="prodSettingsData.price_breakdown.pick_and_pack || 0"
              :shippingToRecipPrice="prodSettingsData.price_breakdown.shipping_to_recipient || 0"
              :tax="prodSettingsData.price_breakdown.tax || 0"
              :customizationFee="prodSettingsData.price_breakdown.options_cost || 0"
              :setupFee="prodSettingsData.setup_fee"
              :totalQty="refillAmount"
              :minQty="productRefillData.min_qty"
            />
          </v-col>

          <v-col cols="12" class="pt-8">
            <settings-panel-payment
              :paymentMethod.sync="payment.method"
              :paymentData.sync="payment.paymentData"
              :fallbackPaymentMethod.sync="fallbackPayment.method"
              :fallbackPaymentData.sync="fallbackPayment.paymentData"
              :shouldHaveFallbackMethod="true"
            />
          </v-col>

          <v-col cols="12" class="pt-10">
            <common-button
              style="width: 192px"
              @click="saveChanges"
              :height="45"
              :loading="sendingData"
            >
              Save
            </common-button>
          </v-col>

          <v-col cols="12" class="auto-refill-panel__error py-9" v-if="errors.length">
            <template v-for="(error, index) in errors">
              <div :key="index" class="pb-2">
                {{ error }}
              </div>
            </template>
          </v-col>
        </template>

        <template v-if="!productHasAllInformation">
          <v-col cols="12">
            <v-divider class="mb-10 mt-5" />
          </v-col>
          <v-col cols="12">
            <common-button
              :disabled="!isLowQtyAlertDataChanged"
              style="width: 192px"
              @click="saveLowQtyAlertData"
              :height="45"
            >
              Save
            </common-button>
          </v-col>
        </template>
      </v-row>
    </v-container>

    <settings-panel-video-dialog v-model="exampleVideo.show" :videoUrl="exampleVideo.url" />
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import PaymentType from '@/components/payment/paymentModule/utils/PaymentType'
import getPaymentBody from '@/components/payment/paymentModule/utils/getPaymentBody'

import Popper from 'vue-popperjs'
import ProductImage from '../ProductImage'
import RefillPriceTable from '../RefillPanel/RefillPriceTable'
import RefillSubtotal from '../RefillPanel/RefillSubtotal'
import SettingsPanelVariantsOptionItem from '../SettingsPanel/SettingsPanelVariantsOptionItem.vue'
import SettingsPanelPayment from '../SettingsPanel/SettingsPanelPayment.vue'
import UpdateStatusSheet from '@/components/UpdateStatusSheet'
import SettingsPanelVideoDialog from '../SettingsPanel/SettingsPanelVideoDialog.vue'
import UnsavedChangesAlert from './common/UnsavedChangesAlert'

import panelShow from './mixins/panelShow'
import negativeNumberHandler from '@/mixins/negativeNumberHandler'

export default {
  name: 'ProductSettingsPanel',
  components: {
    Popper,
    SettingsPanelPayment,
    ProductImage,
    RefillSubtotal,
    RefillPriceTable,
    UpdateStatusSheet,
    UnsavedChangesAlert,
    SettingsPanelVideoDialog,
    SettingsPanelVariantsOptionItem
  },
  mixins: [
    panelShow,
    negativeNumberHandler,
  ],
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    product: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    loading: false,
    rules: {
      required: v => !!v || '',
      requiredNumber: v => (typeof v === 'number' && v >= 0) || '',
      minQty: (minQty = 0) => (v) => v >= minQty
        || (minQty > 1
          ? `Min. qty for this product: ${minQty}`
          : ''),
    },
    sendingData: false,
    userHasUnsavedChanges: false,
    loadingProductSettings: false,
    productRefillData: {},
    prodSettingsData: {},
    refillAmount: 0,
    refillWhenAmountEquals: 10,
    autoRefill: false,
    disableAutoRefillCheckbox: false,
    enableLowQtyAlert: false,
    lowQtyAlertValue: 0,
    errors: [],
    selectedMultiVariantOption: 'on_hold', // on_hold/back_order
    payment: {
      method: PaymentType.BE,
      paymentData: null,
    },
    fallbackPayment: {
      method: null,
      paymentData: null,
    },
    exampleVideo: {
      show: false,
      url: null
    }
  }),
  computed: {
    productVariants () {
      return this.product?.variations?.short || []
    },
    productVariantsOptions () {
      const variants = [].concat(...this.productVariants.map((item) => item.variants))
      const uniqueVariants = uniqBy(variants, item => item.value)
      return groupBy(uniqueVariants, item => item.variant)
    },
    productHasVariants () {
      return this.productVariants.length > 1 ?? false
    },
    fallbackMethodRequired () {
      const { payment, fallbackPayment, productHasVariants } = this
      return payment.method === PaymentType.BE
        && !fallbackPayment.method
        && !fallbackPayment.paymentData
    },
    productHasAllInformation () {
      return this?.productRefillData?.has_all_information ?? false
    },
    isLowQtyAlertDataChanged () {
      const { enableLowQtyAlert, lowQtyAlertValue } = this
      const { enabled, value } = this?.productRefillData?.notify_for_qty_below || {}

      return enableLowQtyAlert !== enabled || Number(lowQtyAlertValue) !== Number(value)
    },
    onHoldStrategyInfo () {
      const { productHasVariants } = this

      return productHasVariants
        ? [
            'For each eGift recipient, 1 unit from each available size will be placed "on hold"',
            'When your eGift is closed, all units on hold that were not redeemed are returned to your available inventory',
          ]
        : [
          'For each eGift recipient, 1 unit is placed "on hold"',
          'When there are no more available units that can be put "on hold", the item will not be available for recipients',
          'When your eGift is closed, all units on hold that were not redeemed are returned to your available inventory',
        ]
    },
    backOrderStrategyInfo () {
      const { productHasVariants } = this

      return productHasVariants
        ? [
            'eGift recipients may redeem their preferred size, even if its out of stock. No units are kept on hold.',
            'Once a recipient redeems an out-of-stock option, an auto-refill for that size is triggered',
          ]
        : [
          'eGift recipients may choose this item even if it’s out of stock. No units are kept on hold.',
          'Once a recipient redeems this item when it’s out-of-stock, an auto-refill is triggered',
        ]
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.loading = true
        this.sendingData = false
        this.setProductAutoRefillData()

        const productSettings = this.getProductSettings()

        const productRefill = Api.get(`customer/purchased-inventory/item/${this.product.item_id}/refill-options`)
          .then(({ data }) => data)
          .catch(() => ({
            customization: {},
            prices: [],
            min_qty: null,
            subtotal: null,
            notify_for_qty_below: {
              enabled: false,
              qty: null
            },
            has_all_information: true
          }))

        Promise.all([productSettings, productRefill]).then(values => {
          this.prodSettingsData = values[0]
          this.productRefillData = values[1]

          this.enableLowQtyAlert = values[1]?.notify_for_qty_below?.enabled
          this.lowQtyAlertValue = values[1]?.notify_for_qty_below?.value
          this.loading = false
        })
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    },
    refillAmount: debounce(function (val) {
      this.loadingProductSettings = true
      this.getProductSettings(val).then((data) => {
        this.prodSettingsData = data
      }).finally(() => { this.loadingProductSettings = false })
    }, 700),
    selectedMultiVariantOption: function (val) {
      if (val === 'back_order') {
        this.autoRefill = true
        this.refillWhenAmountEquals = this.product?.auto_refill?.min_qty ?? 0
        this.refillAmount = this.product?.auto_refill?.value || this.productRefillData?.min_qty || 1
        this.disableAutoRefillCheckbox = true
      } else {
        this.disableAutoRefillCheckbox = false
      }
    },
  },
  methods: {
    setProductAutoRefillData () {
      const {
        value,
        enabled,
        min_qty: minQty,
        payment_method: paymentMethod,
        refill_strategy: strategy,
        fallback_payment_method: fallbackPayment
      } = this.product?.auto_refill
      this.refillAmount = value
      this.autoRefill = enabled
      this.refillWhenAmountEquals = minQty

      this.fallbackPayment = {
        method: fallbackPayment.method,
        paymentData: fallbackPayment.config
      }
      this.selectedMultiVariantOption = strategy

      if (paymentMethod) {
        this.payment = {
          method: paymentMethod?.method ?? PaymentType.BE,
          paymentData: paymentMethod?.config,
        }
      }
    },
    getProductSettings (qty = 0) {
      const emptyResponse = {
        price_breakdown: {
          unit_cost: 0,
          tax: 0,
          storage: 0,
          shipping_to_warehouse: 0,
          shipping_to_recipient: 0,
          pick_and_pack: 0,
          options_cost: 0,
        },
        setup_fee: 0,
      }

      if (qty <= 0) { return Promise.resolve(emptyResponse) }

      return Api.post(
          `customer/purchased-inventory/item/${this.product.item_id}/refill-calculate-cost`,
          { items: [{ item_id: this.product.item_id, qty}] }
        )
        .then((data) => data)
        .catch(() => emptyResponse)
    },
    tryClosePanel () {
      const {
        productRefillData: {
          has_all_information: hasAllInfo,
          notify_for_qty_below: {
            enabled: defaultQtyAlertEnabled,
            value: defaultQtyAlertValue,
          }
        },
        autoRefill,
        refillWhenAmountEquals,
        refillAmount,
        enableLowQtyAlert,
        lowQtyAlertValue,
        payment,
        selectedMultiVariantOption,
        product: {
          auto_refill: {
            value: defaultRefillAmount,
            enabled: defaultAutoRefill,
            min_qty: defaultMinQty,
            payment_method: { method: defaultPaymentMethod },
            refill_strategy: defaultRefillStrategy,
          },
        }
      } = this
      if (!hasAllInfo) this.show = false

      if (autoRefill !== defaultAutoRefill) {
        this.userHasUnsavedChanges = true
      } else if (defaultRefillAmount !== Number(refillAmount)) {
        this.userHasUnsavedChanges = true
      } else if (defaultMinQty !== Number(refillWhenAmountEquals)) {
        this.userHasUnsavedChanges = true
      } else if (defaultPaymentMethod !== payment.method) {
        this.userHasUnsavedChanges = true
      } else if (defaultRefillStrategy !== selectedMultiVariantOption) {
        this.userHasUnsavedChanges = true
      } else if (defaultQtyAlertEnabled !== enableLowQtyAlert) {
        this.userHasUnsavedChanges = true
      } else if (defaultQtyAlertValue !== Number(lowQtyAlertValue)) {
        this.userHasUnsavedChanges = true
      } else {
        this.show = false
      }
    },
    clickOutsideHandler (e) {
      if (e.target.className === 'v-overlay__scrim') {
        this.tryClosePanel()
      }
    },
    saveLowQtyAlertData () {
      const { product, enableLowQtyAlert, lowQtyAlertValue } = this

      this.loading = true
      Api.patch(`/customer/purchased-inventory/item/${product.item_id}/low-qty-alert`, {
        low_qty_alert_enabled: enableLowQtyAlert,
        low_qty_alert_value: lowQtyAlertValue
      }).then((data) => {
        if (data.success) {
          this.$emit('fetchInventoryList')
          this.$cgToast.successBold('Changes saved successfully')
          this.show = false
        }
      }).finally(() => {
        this.loading = false
      })
    },
    saveChanges () {
      // TODO refactor :|
      const {
        product: { item_id: itemId },
        refillAmount,
        refillWhenAmountEquals,
        autoRefill,
        enableLowQtyAlert,
        lowQtyAlertValue,
        payment,
        fallbackPayment,
        selectedMultiVariantOption
      } = this
      const data = {}
      this.errors = []

      if (selectedMultiVariantOption === 'back_order') {
        const input1 = this.$refs['when-refill-drops-to-input'], input2 = this.$refs['refill-amount-input']

        const validationResult = [input1?.$children?.[0], input2?.$children?.[0]]
          .filter((input) => !input.validate(true)).length === 0
        // false when something is not valid
        if (!validationResult) { return; }
      }

      data.autorefill_enabled = autoRefill
      data.min_qty = refillWhenAmountEquals
      data.refill_amount = refillAmount
      data.low_qty_alert_enabled = enableLowQtyAlert
      data.low_qty_alert_value = Number(lowQtyAlertValue)
      data.payment_method = payment.method
      data.refill_strategy = selectedMultiVariantOption

      const paymentBody = getPaymentBody(payment)
      data.payment_method_data = paymentBody

      if (payment.method === PaymentType.BE) {
        const fallbackPaymentBody = getPaymentBody(fallbackPayment)
        data.fallback_payment_method = fallbackPayment.method
        data.fallback_payment_method_data = fallbackPaymentBody
      }

      this.sendingData = true
      Api.patch(`customer/purchased-inventory/item/${itemId}`, data).then(data => {
        if (data.success) {
          this.$cgToast.successBold('Changing the auto product refill settings has been completed!')
          this.$emit('fetchInventoryList')
          this.show = false
        }
        this.sendingData = false
      }).catch(({ response: { data: { errors, message } } }) => {
        this.sendingData = false

        if (errors) this.errors = errors || []
        if (message) this.errors.push(message)
        if (!errors && !message) this.errors = ['We are unable to save changes, please contact your Customer Success Manager']
      })
    }
  }
}
</script>

<style lang="scss">
.auto-refill-panel {
  &__product {
    font-size: 16px;
    line-height: 18px;
    color: #000;

    &--name {
      font-family: 'Lato-Bold', sans-serif;
    }
  }

  &__error {
    color: #d04d55;
  }

  .product-min-qty {
    font-size: 15px;
    font-family: 'Lato-Italic', sans-serif;
    line-height: 18px;
    color: #a1a1a1;
  }

  .v-input {
    &__slot {
      padding: 0 12px 0 8px;

      .v-text-field__suffix {
        font-family: 'Lato-Italic', sans-serif;
        font-size: 13px;
        line-height: 16px;
        color: #a1a1a1;
        height: 20px;
        display: flex;
        align-items: flex-end;
      }

      &::before {
        border-image: none !important;
      }
    }

    .v-text-field__details {
      margin-bottom: 0;
      margin-top: 4px;

      .v-messages__message {
        font-size: 14px !important;
        font-family: 'Lato-Italic', sans-serif;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .v-input--error-hint {
    .v-text-field__details {
      .v-messages__message {
        color: #FF5A60;
      }
    }
  }

  .missing-info-alert {
    font-size: 16px;
    color: #222325;
    line-height: 19px;
    font-family: 'Lato-Regular', sans-serif !important;

    b {
      font-size: inherit;
      color: inherit;
      line-height: inherit;
      font-family: 'Lato-Bold', sans-serif !important;
    }
  }
}
</style>
